<script setup lang="ts">
import type { File } from '~/types';
import { TransitionRoot } from '@headlessui/vue';
import { computed, ref, onMounted, onUnmounted } from 'vue';
// import { useFiles } from '~/composables/files';

export interface GalleryProps {
  items: File[];
  group_by_category?: boolean;
}

const props = defineProps<GalleryProps>();

//const { fileUrl } = useFiles();

const isOpen = ref(false);
const currentItemIdx = ref(0);
const currentCategoryKey = ref<string | null>(null); // Track the selected category when grouping
const isHelpOpen = ref(false);

const { group_by_category = false } = props; // default to false if not provided

const currentItem = computed(() => {
  return currentItems.value[currentItemIdx.value];
});

// Computed property to handle grouped vs. ungrouped mode
// If grouped and a category is selected, return that category’s items; otherwise return all items.
const currentItems = computed(() => {
  if (!group_by_category) {
    return props.items;
  }

  if (group_by_category && currentCategoryKey.value && groupedByCategory.value[currentCategoryKey.value]) {
    return groupedByCategory.value[currentCategoryKey.value].items;
  }

  return [];
});

// Group by category
const groupedByCategory = computed(() => {
  const categoryMap: Record<
    string,
    {
      color?: string;
      headline?: string;
      title?: string;
      items: Array<File>;
    }
  > = {};

  for (const item of props.items ?? []) {
    const categories = item?.categories ?? [];

    for (const categoryWrapper of categories) {
      const category = categoryWrapper?.categories_id;
      if (!category || !category.title) continue;

      const key = category.title;

      if (!categoryMap[key]) {
        categoryMap[key] = {
          color: category.color,
          headline: category.headline,
          title: category.title,
          items: [],
        };
      }

      categoryMap[key].items.push(item);
    }
  }

  return categoryMap;
});

function next() {
  currentItemIdx.value = currentItemIdx.value === currentItems.value.length - 1 ? 0 : currentItemIdx.value + 1;
}

function prev() {
  currentItemIdx.value = currentItemIdx.value === 0 ? currentItems.value.length - 1 : currentItemIdx.value - 1;
}

function toggle() {
  isOpen.value = !isOpen.value;
}

function toggleHelp() {
  isHelpOpen.value = !isHelpOpen.value;
}

function onKeydown(e: KeyboardEvent) {
  if (!isOpen.value) return;

  if (e.key === 'Escape') {
    toggle();
  } else if (e.key === 'ArrowRight') {
    next();
  } else if (e.key === 'ArrowLeft') {
    prev();
  }
}

onMounted(() => {
  window.addEventListener('keydown', onKeydown);
});

onUnmounted(() => {
  window.removeEventListener('keydown', onKeydown);
});

// New function to handle clicking on a category thumbnail
function openCategory(key: string) {
  currentCategoryKey.value = key;
  currentItemIdx.value = 0; // start at the first item of that category
  toggle();
}
</script>

<template>
  <!-- Debugging -->
  <!-- <pre>{{ groupedByCategory }}</pre>
  {{ group_by_category }} -->

  <!-- If not grouping by category, show items as before -->
  <div v-if="!group_by_category" class="gap-4 mt-4 md:columns-3">
    <button
      v-for="(item, itemIdx) in items"
      :key="item.id"
      class="block relative w-full mb-6 overflow-hidden border dark:border-gray-700 rounded-card focus:outline-none"
      @click="
        () => {
          currentItemIdx = itemIdx;
          toggle();
        }
      "
    >
      <div class="relative block w-full overflow-hidden rounded-card group">
        <NuxtImg
          :src="item.id"
          :alt="item.description ?? ''"
          preload
          height="360"
          class="object-cover w-full transition duration-300 group-hover:scale-110"
          placeholder-class="bg-grey-500 animate-pulse"
        />
        <div
          class="absolute inset-0 flex items-center justify-center transition-opacity duration-300 bg-white bg-opacity-75 opacity-0 hover:opacity-100 dark:bg-gray-900 dark:bg-opacity-75"
        >
          <UIcon name="material-symbols:zoom-in-rounded" class="w-12 h-12 text-primary" />
        </div>
      </div>
    </button>
  </div>

  <!-- If grouping by category, show categories as thumbnails -->
  <div v-else class="gap-4 mt-4 md:columns-3">
    <button
      v-for="(cat, key) in groupedByCategory"
      :key="key"
      class="block relative w-full mb-6 overflow-hidden border dark:border-gray-700 rounded-card focus:outline-none"
      @click="openCategory(key)"
    >
      <!-- Category Thumbnail Representation -->
      <div class="relative block w-full overflow-hidden rounded-card group">
        <!-- You might want to show a representative image of the category or a colored box. -->
        <!-- For example, show the first item’s image or a placeholder: -->
        <NuxtImg
          v-if="cat.items[0]"
          :src="cat.items[0].id"
          :alt="cat.items[0].description ?? ''"
          preload
          height="360"
          class="object-cover w-full transition duration-300 group-hover:scale-110"
          placeholder-class="bg-grey-500 animate-pulse"
        />
        <!-- Overlay to show category name -->
        <div
          class="absolute inset-0 flex flex-col items-center justify-center p-4 transition-opacity duration-300 bg-gray-900 bg-opacity-75 opacity-0 hover:opacity-100 dark:bg-gray-900 dark:bg-opacity-75"
        >
          <h3 class="text-xl font-semibold text-white" >{{ cat.title }}</h3>
          <div v-if="cat.headline" class="text-sm text-white dark:text-white" v-html="cat.headline"></div>
          <UIcon name="material-symbols:zoom-in-rounded" class="w-12 h-12 text-primary mt-2" />
        </div>
      </div>
    </button>
  </div>

  <!-- Gallery Modal -->
  <TransitionRoot
    :show="isOpen"
    enter="ease-out duration-300"
    enter-from="opacity-0"
    enter-to="opacity-100"
    leave="ease-in duration-200"
    leave-from="opacity-100"
    leave-to="opacity-0"
  >
    <div class="fixed inset-0 z-50 flex items-center justify-center overflow-hidden bg-gray-900 bg-opacity-75">
      <div class="relative flex flex-col items-center justify-center w-full h-full max-w-7xl">
        <!-- Help Button -->
        <div
          v-auto-animate
          class="absolute z-50 hidden gap-2 text-white bg-opacity-75 item-center md:flex top-4 left-4"
        >
          <div>
            <UButton
              :icon="isHelpOpen ? 'material-symbols:hide' : 'material-symbols:help-outline-rounded'"
              size="xl"
              @click="toggleHelp"
            />
          </div>

          <div
            v-if="isHelpOpen"
            class="flex items-center px-3 py-1 text-xs bg-gray-900 backdrop-blur-sm rounded-button gap-x-4"
          >
            <p>
              Press
              <UKbd value="esc" />
              to close
            </p>
            <p>
              Press
              <UKbd value="←" />
              or
              <UKbd value="→" />
              to navigate
            </p>
          </div>
        </div>
        <!-- Close Button -->
        <!--button to direct user to full gallery page with passed group by type but only if group_by_category is true-->
        <UButton
          v-if="group_by_category"
          icon="material-symbols:open-in-new"
          size="xl"
          trailing
          class="absolute z-50 top-4"
          @click="() => $router.push({ name: 'projects', query: { group_by_category: currentCategoryKey } })"
        >
         See More Of Our {{ currentCategoryKey }} Projects
        </UButton>
        <UButton class="absolute z-50 top-4 right-4" icon="material-symbols:close-rounded" size="xl" @click="toggle" />

        <div class="flex items-center justify-center w-full h-full">
          <!-- Nav Buttons -->
          <UButton class="absolute z-50 left-4" icon="material-symbols:arrow-back-rounded" size="xl" @click="prev" />
          <UButton
            class="absolute z-50 right-4"
            icon="material-symbols:arrow-forward-rounded"
            size="xl"
            @click="next"
          />

          <!-- Image Area -->
          <div class="relative flex flex-col items-center justify-center w-full h-full p-20">
            <p
              v-if="currentItem?.description"
              class="inline-block px-6 py-2 text-sm text-white bg-gray-900 rounded-t-xl"
            >
              {{ currentItem.description }}
            </p>

            <template v-for="(item, idx) in currentItems" :key="item.id">
              <NuxtImg
                v-show="currentItemIdx === idx"
                :src="item.id"
                :alt="item.description ?? ''"
                preload
                height="800"
                class="object-contain w-full rounded-card"
                placeholder-class="bg-grey-500 animate-pulse"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </TransitionRoot>
</template>
